* {
  font-family: Helvetica;
}
body {
  margin: 0;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
}

.footer {
  background-color: green;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
}
.footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}
.copyright {
  color: white;
  list-style: none;
  font-size: large;
}
.ftr {
  left: 0;
  bottom: 0;
  width: 100%;
}

#firstp {
  font-size: 20px;
}
#lastp {
  color: blue;
  font-size: 20px;
}
#seconedp {
  color: blue;
  font-size: 20px;
}
.our-team {
  color: darkgreen;
  text-align: center;
}
.text-center {
  color: darkgreen;
}

.proj1specs li {
  list-style: none;
  color: darkgreen;
  font-size: large;
}
.project1all {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid green;
}
.proj1txt {
  text-align: center;
  margin-top: 90px;
  margin-bottom: 25px;
  margin-right: 170px;
}

.whySolarHead :active {
  color: #ffc107;
}
.stepsOfGoingGreen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stepsOfGoingGreen h3 {
  text-align: center;
}
.body-gosolar {
  font-size: large;
}

.mui-nav {
  background: green !important;
  height: 70px;
}

.mui-nav a,
li {
  color: white !important;
  text-decoration: none;
  list-style: none;
  height: 100%;
}
.proj1specs li {
  color: darkgreen !important;
}
.contactus-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contactus-form {
  width: 500px;
  background-color: ivory;
  border: 3px solid darkgreen;
  margin-right: 30px;
}
.contactus-bottom {
  background-color: ivory;
  width: 100%;
  text-align: center;
}

.managers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.txt-logo {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    white 0%,
    ivory 29%,
    darkgreen 67%,
    #ffc107 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  animation-direction: reverse;
  display: inline-block;
  font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.pic-maskk {
  background-color: rgba(52, 52, 52, 0.8);
  color: white;
  border-radius: 5px;
}
.hiringnow {
  background-color: ivory;
  border: 3px solid darkgreen;
}
.hiringnowtitle {
  text-decoration: double;
}
