* {
  font-family: helvetica;
}
.container {
  margin: 1rem;
}
#logo {
  margin-top: 3.5rem;
  margin-left: 40px;
  height: 350px;
  width: 500px;
  text-align: center;
}
#img-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.electrical-dpt img {
  margin-top: 30px;
  width: 300px;
  height: 200px;
}
.row-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.eng-parag {
  margin-top: 30px;
  font-size: large;
  margin-left: 10px;
}
.new-logo {
  max-height: 30px;
  max-width: 30px;
}

.lo {
  display: flex;
  justify-content: center;
}
.watt-echo {
  color: darkgreen;
}
.welcome-parag {
  font-size: large;
}
.welcome {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: ivory;
}
.projects {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ulist li {
  list-style: none;
}
.greentext {
  color: darkgreen;
}
.testimony {
  background-color: ivory;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.testimony Card {
  align-items: center;
}

.hbutton {
  align-items: center;
}

.hbutton:hover {
  background-color: transparent;
}

.animate-char {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    white 0%,
    ivory 29%,
    darkgreen 67%,
    #ffc107 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: hsl(0, 0%, 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  animation-direction: reverse;
  display: inline-block;
  font-size: 190px;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.pic-mask {
  background-color: rgba(52, 52, 52, 0.8);
  color: white;
  border-radius: 5px;
}
.wel {
  text-align: center;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  animation-direction: reverse;
  display: inline-block;
  font-size: 33px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.animate {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-direction: reverse;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 16px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.back-to-top {
  position: fixed;
  bottom: 90px;
  right: 30px;
  font-size: 60px;
  background: transparent;
  color: #ffc107;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  box-shadow: 0 5px 10px ivory;
  margin-bottom: 10px;
}
